import styled from "@emotion/styled";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ChevronLeftRounded from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Button, Grid } from "@mui/material";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { useSessionStorage } from 'usehooks-ts';
import { ImageInfo } from "../Images";
import { CartItem } from "../models";

const ImageTile = styled.div`
  max-width: 700px;
  margin: 0 auto;

  @media (max-width: 786px) {
    margin: 25px auto 0 auto;
  }
`;

const ImageTitle = styled.h2`
  font-size: 22pt;
`;

const Price = styled.p`
  font-size: 22pt;
`;

const Specs = styled.ul`
  margin: 5pt 0;
`;

const Spec = styled.li`
  line-height: 20pt;
`;

const Smaller = styled.p`
  font-size: 14pt;
`;

const SliderButton = styled.button`
  width: 40px;
  height: 40px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  padding: 2px 0 0 0;
  margin: 10px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

const CartButton = styled(Button)`
  margin-top: 20pt;
  text-transform: none;
`

const slider = {
  indicators: true,
  prevArrow: (
    <SliderButton>
      <ChevronLeftRounded fontSize="large" />
    </SliderButton>
  ),
  nextArrow: (
    <SliderButton>
      <ChevronRightRoundedIcon fontSize="large" />
    </SliderButton>
  ),
};

const Image = ({
  id,
  title,
  price,
  longText,
  dimentions,
  medium,
  framed,
}: ImageInfo) => {
  const [cartContent, setCartContent] = useSessionStorage<CartItem[]>('cart-contents', []);

  const onAddToCart = () => {
    const updatedCart = [
      ...cartContent,
      {
        name: title,
        sku: id,
        quantity: "1",
        unit_amount: {
          currency_code: "GBP",
          value: price.toString(),
        }
      },
    ]
    setCartContent(updatedCart);
  }

  const removeFromCart = () => {
    const updatedCart = cartContent.filter(item => item.sku !== id);
    setCartContent(updatedCart);
  }

  const itemInCart = cartContent.find((item) => item.sku === id) !== undefined;

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={5}
        columns={{ xs: 1, md: 2, lg: 2, xl: 2 }}
      >
        <Grid item xs={1}>
          <ImageTile>
            <Slide {...slider}>
              <div className="each-slide-effect">
                <img
                  src={"/images/gallery/" + id + "/700_1.jpeg"}
                  width={"100%"}
                  alt={title}
                />
              </div>
              <div className="each-slide-effect">
                <img
                  src={"/images/gallery/" + id + "/700_2.jpeg"}
                  width={"100%"}
                  alt={title}
                />
              </div>
              <div className="each-slide-effect">
                <img
                  src={"/images/gallery/" + id + "/700_3.jpeg"}
                  width={"100%"}
                  alt={title}
                />
              </div>
            </Slide>
          </ImageTile>
        </Grid>
        <Grid item xs={1}>
          <ImageTitle>{title}</ImageTitle>
          {longText}

          {price === 0 ? (
            <CartButton variant="outlined" disabled>Sorry, this artwork is not available for purchase.</CartButton>
          ) : (
            <>
              <Price>GBP {price}</Price>
              {itemInCart ? (
                <CartButton color="error" variant="outlined" onClick={removeFromCart} startIcon={<RemoveShoppingCartIcon />}>Remove from basket</CartButton>
              ) : (
                <CartButton variant="contained" onClick={onAddToCart} startIcon={<AddShoppingCartIcon />}>Add to basket</CartButton>
              )}
            </>
          )}

          <Smaller>Specifications:</Smaller>
          <Specs>
            <Spec>
              <b>Dimentions:</b> {dimentions}
            </Spec>
            <Spec>
              <b>Medium:</b> {medium}
            </Spec>
            <Spec>
              <b>Framed:</b> {framed}
            </Spec>
          </Specs>

          <Smaller>Price includes postage and packaging for UK mainland.</Smaller>
        </Grid>
      </Grid >
    </>
  );
};

export default Image;
