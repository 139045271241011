import ga4 from 'react-ga4'

const TRACKING_ID = process.env.REACT_APP_GA4_TRACKING_ID as string

export const init = () => ga4.initialize(TRACKING_ID, { 
  testMode: TRACKING_ID ? false : true
})

export const sendEvent = (name: string) => ga4.event('screen_view', {
  app_name: "bhagyapereraart.com",
  screen_name: name,
})

export const sendPageview = (path: string) => ga4.send({ 
  hitType: 'pageview', 
  page: path 
})