import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import Images, { ImageInfo } from "../Images";

const ImageTile = styled.div`
  display: block;
  max-width: 300px;
`;

const ImageTitle = styled.h2`
  text-align: left;
  margin: 5pt 0pt;
`;

const ImageDescription = styled.p`
  margin: 5pt 0pt;
`;

const NavLinkItem = styled(NavLink)`
  text-decoration: none;
  color: #333;
`;

const Tile = ({ id, title, shortText }: ImageInfo) => {
  return (
    <ImageTile id={id}>
      <NavLinkItem to={`/gallery/` + id}>
        <img
          src={"/images/gallery/" + id + "/300.jpeg"}
          width={"100%"}
          alt={title}
        />
        <ImageTitle>{title}</ImageTitle>
        <ImageDescription>{shortText}</ImageDescription>
      </NavLinkItem>
    </ImageTile>
  );
};

const Gallery = () => {
  return (
    <Grid
      container
      columnGap={10}
      rowGap={10}
      width={"100%"}
      justifyContent={"center"}
      columns={4}
    >
      {Images.map((element) => {
        return (
          <Grid item key={element.id}>
            <Tile {...element} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Gallery;
