import { Stack } from "@mui/material";
import { SocialIcon } from "react-social-icons";
import styled from "@emotion/styled";

const Container = styled.div`
  margin-top: 30pt;
  margin-bottom: 30pt;
  text-align: center;
`;

const FooterStack = styled(Stack)`
  margin-top: 40px;
`;

const Footer = (): JSX.Element => {
  return (
    <Container>
      <FooterStack spacing={2} direction={"row"} justifyContent={"center"}>
        <SocialIcon
          url="https://www.instagram.com/bhagya_perera_art"
          bgColor="#333"
          fgColor="#fff"
          target="_blank"
        />
        <SocialIcon
          url="https://www.facebook.com/bhagyaperera.art.7"
          bgColor="#333"
          fgColor="#fff"
          target="_blank"
        />
        <SocialIcon
          url="mailto:bhagyapereraart@gmail.com"
          bgColor="#333"
          fgColor="#fff"
          target="_blank"
        />
      </FooterStack>

      <FooterStack>&copy; Bhagya Perera, 2023</FooterStack>
    </Container>
  )
}

export default Footer;