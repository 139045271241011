import "@fontsource/raleway"; // Defaults to weight 400
import "@fontsource/raleway/400.css"; // Specify weight

import "@fontsource/kaushan-script"; // Defaults to weight 400
import "@fontsource/kaushan-script/400.css"; // Specify weight

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import ComingSoon from "./ComingSoon";
import './index.css';
import reportWebVitals from './reportWebVitals';

declare module '*.png';

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    {/^(local|pr3vi3w).*/.test(window.location.hostname) ? <App /> : <ComingSoon />}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
