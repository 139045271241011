import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Container from "./Container";
import About from "./routes/About";
import Contact from "./routes/Contact";
import Gallery from "./routes/Gallery";
import Home from "./routes/Home";
import Images from "./Images";
import Image from "./routes/Image";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Cart from "./routes/Cart";

const App = () => {
  const routes = [
    {
      path: "/",
      element: (
        <Container title="Home">
          <Home />
        </Container>
      ),
    },
    {
      path: "/gallery",
      element: (
        <Container title="Gallery">
          <Gallery />
        </Container>
      ),
    },
    {
      path: "/about",
      element: (
        <Container title="About">
          <About />
        </Container>
      ),
    },
    {
      path: "/contact",
      element: (
        <Container title="Contact">
          <Contact />
        </Container>
      ),
    },
    {
      path: "/basket",
      element: (
        <Container title="Basket">
          <Cart />
        </Container>
      )
    }
  ];

  Images.forEach((element) => {
    routes.push({
      path: "/gallery/" + element.id,
      element: (
        <Container title={"Gallery - " + element.title}>
          <Image {...element} />
        </Container>
      ),
    });
  });

  const router = createBrowserRouter(routes);

  const initialOptions = {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID as string,
    currency: "GBP",
    intent: "capture",
  };

  return (
    <div className="App">
      <PayPalScriptProvider options={initialOptions}>
        <RouterProvider router={router} />
      </PayPalScriptProvider>
    </div>
  );
};

export default App;
