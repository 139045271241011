import styled from "@emotion/styled";
import { ReactNode } from "react";

const StyledLink = styled.a`
  color: #000;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export interface ImageInfo {
  id: string;
  title: string;
  featuredIndex?: number;
  price: number;
  dimentions: string;
  medium: string;
  framed: string;
  shortText: string;
  longText: ReactNode;
}

const Images: Array<ImageInfo> = [
  {
    id: "racoon",
    title: "Innocence Illuminated",
    price: 550,
    dimentions: "16 x 20 inches	/ 40.64 x 50.8 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Raccoons are renowned for their mischievous and entertaining antics.",
    longText: (
      <>
        <p>
          Raccoons are renowned for their mischievous and entertaining antics.
          They currently hold a "least concerned" status in terms of endangerment, owing to the rise in their numbers..
        </p>
        <p>
          Yet, when I observe them, I can't help but see their innocence as they strive to adapt and
          thrive in a challenging world. In my painting, I aim to convey their narrative through the
          illumination of their innocent eyes, revealing the underlying story of their resilience and
          determination to find their place in this complex ecosystem.
        </p>
      </>
    )
  },
  {
    id: "fallowdeer",
    title: "Willow the deer",
    price: 375,
    dimentions: "10x12 inches	/ 25.4cm x 30.48cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Puffins have earned a spot on the Red List of UK Birds of Conservation Concern.",
    longText: (
      <>
        <p>
          Fallow deer were first brought to Britain from the western Mediterranean during the
          Roman period when they were kept within enclosures known as ‘vivaria’. Genetic analysis has s
          hown that these Roman fallow deer went extinct in Britain following the collapse of the Roman Empire.
          It was not until the 11th century that fallow deer were reintroduced, this time from the eastern Mediterranean.
        </p>
        <p>
          Read more about fallow deer on <StyledLink href="https://bds.org.uk/information-advice/about-deer/deer-species/fallow-deer/" target="_blank">
            The British Deer Society website
          </StyledLink>
        </p>
        <p>
          The innocence exuded by this baby deer immediately captivated my attention when I first laid
          eyes on the original photograph. Inspired by this pure and untarnished quality, I embarked on the
          journey of translating it onto canvas, seeking to preserve the charm and grace of this young creature's presence.
        </p>
      </>
    ),
    featuredIndex: 2,
  },
  {
    id: "grasssnake",
    title: "Luna the Grass Snake",
    price: 375,
    dimentions: "10x12 inches	/ 25.4cm x 30.48cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Grass Snakes proudly holding the title of Britain's longest snake",
    longText: (
      <>
        <p>
          Luna is a Grass Snake, proudly holding the title of Britain's longest snake.
          While they typically exhibit a predominantly gray hue, I've taken the liberty to depict Luna in a
          more vibrant and colorful rendition.
        </p>
        <p>
          Grass snakes, though once widespread throughout England and Wales, are now facing a concerning decline,
          as are our other native species of snakes and lizards. In recognition of this concerning trend,
          they have been included in the Biodiversity Action Plan (BAP) to aid in their conservation. Additionally,
          it's worth noting that Grass snakes are afforded legal protection in Great Britain, making it unlawful to
          intentionally harm, injure, or engage in the trade of these fascinating reptiles.
        </p>
      </>
    )
  },
  {
    id: "puffin",
    title: "Stevan the Puffin",
    price: 375,
    dimentions: "10x12 inches	/ 25.4cm x 30.48cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Puffins have earned a spot on the Red List of UK Birds of Conservation Concern.",
    longText: (
      <>
        <p>
          Steven marks the inaugural member of my "British Wild Isles" mini series, and he's a charming Puffin.
          These distinctive birds, known as Puffins, grace the shores of both the North Pacific and North Atlantic oceans,
          particularly on various islands. Notably, while the largest breeding populations can be found in Iceland and Norway,
          the British Isles proudly harbor approximately 10 percent of the global Puffin population.
        </p>
        <p>
          It's essential to recognize the conservation status of these endearing creatures. Puffins have earned a spot on
          the Red List of UK Birds of Conservation Concern and have been designated as "Vulnerable" on the IUCN Red List.
          This vulnerability arises from their breeding population being highly concentrated in a limited number of sites,
          making them particularly susceptible to adverse changes in their environment.
          Protecting and preserving these Puffins and their habitats is vital to ensuring their continued presence in our natural world.
        </p>
        <p>
          The captivating innocence reflected in the glaze of Puffins has drawn me to these remarkable birds.In my artistic endeavor,
          I've sought to capture the essence of their poignant, almost wistful gaze while contrasting it against a vibrant and
          colorful background. This contrast serves to emphasize not just their intrinsic sadness but also the enduring hope
          that resides within these beautiful creatures.
        </p>
      </>
    )
  },
  {
    id: "horse",
    title: "End of the day",
    price: 790,
    dimentions: "24x 30 inches	60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Horses are some of the most hard working animals of the modern age ever since 4000 BCE humans began.",
    longText: (
      <>
        <p>
          Horses are some of the most hard working animals of the modern age ever since 4000 BCE humans began
          domesticating them for various activities, mainly for riding in warfare.At the end of the day, if we look closer at the horse, we can see how tired they are from our demands.
          When I saw the original photo, the idea hooked me. This is the idea that gave it its theme.
        </p>
        <p>
          I wanted to show the sadness and exhaustion on its sullen eyes and how its lowered head position reflects his feeling on the painting.
          The shaded colour pallet is used to reflect the emotions the horse feels after a hard day's work.
        </p>
      </>
    ),
    featuredIndex: 1,
  },
  {
    id: "turtle",
    title: "Reflect",
    price: 790,
    dimentions: "24x 30 inches	60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Turtles are classified as Endangered animals by WWF .",
    longText: (
      <>
        <p>
          Turtles are classified as Endangered animals by WWF and various human actitivities had played a major part of it.
          When I saw the original photo felt like the this turtle is wondering whether it should come to the surface or not.
          Reflecting on what had happened to his family and friends who went up who never came back.
        </p>
        <p>
          It inspired me to paint this picture with a dim light showing a glimmer of hope the turtles from the work that
          orgnasations such as WWF are doing to help.I want this painting to be a reflect on humans and their behaviours. Save these animals.
        </p>
      </>
    ),
    featuredIndex: 3,
  },
  {
    id: "owl",
    title: "Focus",
    price: 550,
    dimentions: "16 x 20 inches	/ 40.64 x 50.8 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "The great horned owl, while not officially listed as an endangered species, has a fascinating pattern of behavior.",
    longText: (
      <>
        <p>
          The great horned owl, while not officially listed as an endangered species, has a fascinating pattern of behavior.
          These magnificent birds tend to steer clear of densely populated areas, preferring the wide expanses of the American landscape.
        </p>
        <p>
          What sets these owls apart is their remarkable adaptability, seemingly undeterred by shifts in
          the environment or human encroachment. Unfortunately, the pesticides employed to control rodents are taking a toll on them.
        </p>
        <p>
          The striking allure of its beautiful eyes drew me irresistibly to paint this magnificent bird.
          The intensity and sharpness of its colors presented a unique challenge, leading me to venture beyond
          my usual grayscale paintings. I thoroughly enjoyed this artistic challenge and the opportunity to capture
          the essence of this remarkable creature.
        </p>
      </>
    ),
    featuredIndex: 4,
  },
  {
    id: "lion",
    title: "The Stare",
    price: 0,
    dimentions: "39.3 x 19.6in /100 x 50 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Lions are classified as vulnerable animals by WWF.",
    longText: (
      <>
        <p>
          Lions are classified as vulnerable animals by WWF.
          Being one of the most well-known animals in the world, we would not want these majestic creatures to disappear.
        </p>
        <p>
          When I saw the original photo, I could see it peering off to the distance, expressing its proud posture as the
          king of the jungle. With the painting I am trying to highlight the power it wields, the strength it bares and
          the courage it possesses.
        </p>
        <p>
          This painting was a personal, but new, challenge for me as I have not attempted to paint a lion before.
          It took me many weeks to complete it, but all not in vein as it does justice to lions; this is what I wanted to achieve.
          Now its hanging in my living room proudly, on its throne above all of us..
        </p>
      </>
    )
  },
  {
    id: "puppy",
    title: "The look of love",
    price: 0,
    dimentions: "10x12 inches	/ 25.4cm x 30.48cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "This puppy painting holds a special place among our family's favorites.",
    longText: (
      <>
        <p>
          This puppy painting holds a special place among our family's favorites.
        </p>
        <p>
          While it doesn't depict a family pet, we adore its appearance and composition for its innocence.
        </p>
      </>
    )
  },
  {
    id: "buddha",
    title: "Lord Gauthama Buddha",
    price: 0,
    dimentions: "24x 30 inches/ 60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Buddha's everlasting kind geasutre had inspired me to embark on this piece.",
    longText: (
      <>
        <p>
          This is one of the first paintings I've ever created. Buddha's everlasting kind geasutre had
          inspired me to embark on this piece. Although I aimed for a contemporary perspective,
          I believe I've managed to capture the essence of his wisdom and presence.
        </p>
        <p>
          Although I aimed for a contemporary perspective, I believe I've managed to capture
          the essence of his wisdom and presence.
        </p>
      </>
    )
  },
  {
    id: "elephant",
    title: "The Elephant.",
    price: 0,
    dimentions: "24x 30 inches / 60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "The affection that Sri Lankans hold for elephants is widely acknowledged.",
    longText: (
      <>
        <p>
          The affection that Sri Lankans hold for elephants is widely acknowledged.
          These majestic creatures play a significant role in numerous cultural ceremonies,
          ingrained deeply in our traditions.
        </p>
        <p>
          When I first encountered the original photo, I was irresistibly drawn to it,
          captivated by the portrayal of this gentle giant.
        </p>
      </>
    )
  },
  {
    id: "gorilla",
    title: "The wise one",
    price: 0,
    dimentions: "24 x 30 inches / 60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Cross River gorillas hold the unfortunate designation of being classified as critically endangered animals by WWF.",
    longText: (
      <>
        <p>
          Cross River gorillas hold the unfortunate designation of being classified as critically endangered animals by WWF.
          Every encounter with these remarkable animals never fails to astonish me due to their intricate behavior,
          distinctive attitudes, and vivid emotions.
        </p>
        <p>
          In naming this particular piece, "The Wise One," I was deeply moved by the hopeful expression i
          n the gorilla's eyes. It is these glimpses of hope and resilience in their gaze that continually
          inspire me and reaffirm my belief in a better future.
        </p>
      </>
    )
  },
  {
    id: "snowleopard",
    title: "Trust the wait",
    price: 0,
    dimentions: "24 x 30 inches / 60.96 x 76.2 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "Snow leopards hold the unfortunate designation of being classified as vulnerable animals by WWF.",
    longText: (
      <>
        <p>
          The magnificent snow leopards have earned a classification as "vulnerable"
          on the endangered list by WWF. This designation underscores the critical need for their protection..
        </p>
        <p>
          In this portrayal, a young cub gazes into the horizon with a glimmer of hope,
          yearning for a positive future not only for himself but also for his fellow kin.
          Despite the long and uncertain journey ahead, he perseveres, putting his trust in the natural process of survival.
        </p>
      </>
    )
  },
  {
    id: "elephantfamily",
    title: "Family",
    price: 0,
    dimentions: "40x32 inches	101.6 x 81.28 cm",
    medium: "Acrylic on canvas",
    framed: "No",
    shortText:
      "In my early years, I was introduced to the concept of a trio of elephants: a father, a baby, and a mother or sister.",
    longText: (
      <>
        <p>
          In my early years, I was introduced to the concept of a trio of elephants: a father, a baby, and a mother or sister,
          known as "Thunpath Raana" in Sinhala. This trio is known for their formidable nature, especially when it comes
          to safeguarding the calf. They are willing to put their own lives on the line to protect the young one.
        </p>
        <p>
          This elephant family resonates deeply with my own personal experiences. The love and the profound sense of protectiveness
          I feel for my husband and my son mirror the unwavering bond within this remarkable trio.
        </p>
      </>
    )
  }
];

export default Images;
