import styled from "@emotion/styled";
import { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Nav from "./Navigation";
import { Helmet } from "react-helmet";
import { useAnalytics } from './useAnalytics'

const ContentContainer = styled.div`
  margin: 0 auto;
  padding: 10pt 40pt;
  max-width: 1500px;
  @media (max-width: 786px) {
    padding: 0pt 20pt;
  }
`;


const Container = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  useAnalytics();
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bhagya Perera Art - {title}</title>
        <link rel="canonical" href={process.env.REACT_APP_PUBLIC_URL} />
      </Helmet>
      <Header />
      <Nav />
      <ContentContainer>{children}</ContentContainer>
      <Footer />
    </div>
  );
};

export default Container;
