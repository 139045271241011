import { Grid } from "@mui/material";

const About = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={5}
      columns={{ xs: 1, md: 2, lg: 2, xl: 2 }}
    >
      <Grid item xs={1}>
        <img src={"images/profile.png"} width={"100%"} alt={"Profile"} />
      </Grid>
      <Grid item xs={1}>
        <h4>Passion Unveiled</h4>
        <p>
        From the earliest days of my childhood, animals have consistently captivated and astounded me. As the years have passed, one thing remains unchanged: animals are, and forever will be, my muse.
        </p> 
        <h4>Artistic Journey</h4>
        <p>
        My journey as a self-taught hobbyist artist has been a fascinating one. It commenced with humble doodles, and it was in 2017, after undergoing a transformative Cognitive Behavioral Therapy (CBT) course, that I found myself drawn into the world of painting.
        </p> 
        <h4>Breathing Life into Canvas</h4>
        <p>
        I hope through my paintings, you find my passion for animals on the canvas, where their grace, emotions, and enchanting behaviors are brought vividly to life. Each painting reflects not only my love for animals and nature but also the beautiful chaos of life.
        </p> 
        <h4>Conservation Commitment</h4>
        <p>In an effort to give back and contribute to the preservation of the magnificent creatures that inspire my art, I have committed to donating 10% of the proceeds from every sale to an animal conservation charity or project. It is my sincere hope that through this gesture, we can collectively play a role in ensuring that the enduring beauty of these animals continues to grace our planet for generations to come.</p>
        <h4>Life in England</h4>
        <p>Today, I live with my husband and my son in a charming village in England. Amidst the daily responsibilities, including my day job and quality time spent with my family, I have diligently carved out moments to indulge in my passion for painting.</p>
        <h4>Join the Journey</h4>
        <p>Your appreciation and support mean the world to me, and I invite you to explore and immerse yourself in the world of art I've lovingly created. Thank you for being a part of this artistic journey with me. Together, we can make a meaningful impact on the future of these remarkable beings and their environments.</p>
      </Grid>
    </Grid>
  );
};

export default About;
