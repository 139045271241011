import styled from "@emotion/styled";
import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

const NavContainer = styled.nav`
  margin: 40px 0 35px 0;
  @media (max-width: 786px) {
    margin: 10px 0;
  }
  text-align: center;
`;

const NavList = styled.ul`
  padding: 0;
`;

const NavListItem = styled.li`
  display: inline-block;
  padding: 10pt;
`;

const NavLinkItem = styled(NavLink)`
  text-decoration: none;
  color: #333;
  font-size: 20pt;
  @media (max-width: 786px) {
    font-size: 14pt;
  }
  padding: 2pt 0;
  &:hover {
    color: #000;
    text-decoration: underline;
    text-underline-offset: 8px;
  }
  &.active {
    color: #000;
    font-weight: bold;
  }
`;

const Navigation = () => {
  const location = useLocation();
  const { pathname } = location;
  const [ tooltip, setTooltip ] = useState(false);

  useEffect(() => {
    setTooltip(false)
    setTimeout(()=>{
      setTooltip(pathname.startsWith('/gallery/'))
    }, 500)
  }, [pathname])

  return (
    <NavContainer>
      <NavList>
        <NavListItem>
          <NavLinkItem to={`/`}>home</NavLinkItem>
        </NavListItem>
        <NavListItem>
          <Tooltip title={'Click here to go back to the gallery'} arrow={true} open={tooltip}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 0],
                    },
                  },
                ],
              },
            }}>
            <NavLinkItem to={`/gallery`}>gallery</NavLinkItem>
          </Tooltip>
        </NavListItem>
        <NavListItem>
          <NavLinkItem to={`/about`}>about</NavLinkItem>
        </NavListItem>
        <NavListItem>
          <NavLinkItem to={`/contact`}>contact</NavLinkItem>
        </NavListItem>
      </NavList>
    </NavContainer>
  );
};

export default Navigation;
