import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const CenterText = styled.p`
  text-align: center;
`;

const Contact = () => {
  return (
    <Grid container rowSpacing={2} columnSpacing={5}>
      <Grid item xs={12} textAlign={"center"}>
        <CenterText>
          If you like to get in touch about my work, please do get in touch.
        </CenterText>
        <CenterText>
          I will try my best to get back to you within couple of days.
        </CenterText>
        <CenterText>You can contact me via my socials or email.</CenterText>
      </Grid>
    </Grid>
  );
};

export default Contact;
