import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const HeaderContainer = styled.header`
  background-color: #333;
  text-align: center;
`;

const HeaderGrid = styled(Grid)`
  margin: 0 auto;
`;

const Heading = styled.div`
  padding: 10pt;
  color: #fff;
  margin: 0;
  font-size: 20pt;
  height: calc(100vh - 30vh);
  padding-top: 30vh;
  @media (max-width: 786px) {
    font-size: 15pt;
  }
`;

const ComingSoon = () => {
  return (
    <HeaderContainer>
      <HeaderGrid container spacing={0}>
        <Grid item xs={12}>
          <Heading>
            <h1 style={{ textAlign: 'center' }}>Bhagya Perera Art</h1>
            <p style={{ textAlign: 'center' }}>Coming soon!</p>
          </Heading>
        </Grid>
      </HeaderGrid>
    </HeaderContainer>
  );
};

export default ComingSoon;
