import styled from "@emotion/styled";
import CloseIcon from '@mui/icons-material/Close';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import { Button, Dialog, DialogContent, Grid, IconButton } from "@mui/material";
import { PayPalButtons } from "@paypal/react-paypal-js";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSessionStorage } from 'usehooks-ts';
import { CartItem, OrderCompletionResponse, PayPalError } from "../models";

const Heading = styled.h3`
  font-size: 16pt;
  text-align: center;
  margin: -10px 0 50px 0;
  @media (max-width: 786px) {
    margin: 22px 0 30px 0;
  }
`;

const CheckoutSummary = styled.div`
  margin-top: 0;
  @media (max-width: 899px) {
    margin-top: 40px;
  }
  line-height: 18pt;
`

const CheckoutItem = styled.div`
  line-height: 20pt;
`

const CartButton = styled(Button)`
  text-transform: none;
  margin-top: 8px;
`

const CenterText = styled.p`
  text-align: center;
`;

const StyledLink = styled(Link)`
  color: #000;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Cart = () => {
  const [cartContent, setCartContent] = useSessionStorage<CartItem[]>('cart-contents', []);
  const orderTotal = cartContent.map((item) => parseFloat(item.unit_amount.value)).reduce((a, b) => a + b, 0)
  const [orderStatus, setOrderStatus] = useState<OrderCompletionResponse>()
  const [orderError, setOrderError] = useState<PayPalError | undefined>()

  const removeFromCart = (id: string) => {
    const updatedCart = cartContent.filter(item => item.sku !== id);
    setCartContent(updatedCart);
  }

  const createOrder = (data: any) => {
    // Order is created on the server and the order id is returned
    return fetch("/api/checkout?op=create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      // use the "body" param to optionally pass additional order information
      // like product skus and quantities
      body: JSON.stringify({
        items: cartContent,
        amount: {
          currency_code: "GBP",
          value: orderTotal.toString(),
          breakdown: {
            item_total: {
              currency_code: "GBP",
              value: orderTotal.toString(),
            }
          }
        }
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Order response: " + JSON.stringify(data))
        setOrderStatus(data);

        if (data.status === "CREATED") {
          return data.id;
        } else {
          try {
            setOrderError(data);
          } catch (error) {
            setOrderError({ name: "UNKNOWN_ERROR" })
          }
        }
      });
  };

  const onApprove = (data: any) => {
    // Order is captured on the server and the response is returned to the browser
    return fetch("/api/checkout?op=approve", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        orderID: data.orderID
      })
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Approve response: " + JSON.stringify(data))
        setOrderStatus(data);

        if (data.status === "COMPLETED") {
          setCartContent([]);
        } else {
          try {
            setOrderError(data);
          } catch (error) {
            setOrderError({ name: "UNKNOWN_ERROR" })
          }
        }
      });
  };

  return cartContent.length === 0 && orderStatus ? (
    <>
      <Heading>Your order was {orderStatus.status}</Heading>
      <CenterText>
        Thank you so much for your order!<br />
        You should shortly receive an email from PayPal confirming your order.<br />
        I will get your order on the way as soon as possible, and you will get another email when its on its way!
      </CenterText>
      <CenterText>Order reference: <b>{orderStatus.id}</b></CenterText>
      <CenterText>Please note down this order number. It would be easier to track down your order if you have it handy.</CenterText>
    </>
  ) : cartContent.length === 0 ? (
    <>
      <Heading>Your basket is empty</Heading>
      <CenterText>
        Would you like to have a look my <StyledLink to={'/gallery'}>gallery</StyledLink> to
        see if there's something you like?</CenterText>
      <CenterText>
        If you already added an artwork to your basket but its not showing up here,
        please do <StyledLink to={'/contact'}>let me know</StyledLink>, I will have a look in to it.
      </CenterText>
    </>
  ) : (
    <div>
      <Heading>Your order summary</Heading>
      <Grid container columns={{ xs: 1, md: 2, lg: 2, xl: 2 }}>
        <Grid item xs={1}>
          <Grid container spacing={3} columns={{ xs: 2, md: 2, lg: 2, xl: 2 }}>
            {cartContent.map((item) => (
              <React.Fragment key={item.sku}>
                <Grid item xs={1} style={{ textAlign: 'right' }}>
                  <img
                    src={"/images/gallery/" + item.sku + "/300.jpeg"}
                    style={{ maxWidth: 150 }}
                    alt={item.name}
                  />
                </Grid>
                <Grid item style={{ lineHeight: 1.5 }} xs={1}>
                  <CheckoutItem>
                    <strong>{item.name}</strong>
                    <br />
                    {item.unit_amount.currency_code} {item.unit_amount.value}
                    <br />
                    <CartButton variant="outlined" size="small" onClick={() => removeFromCart(item.sku)} startIcon={<RemoveShoppingCartIcon />}>Remove</CartButton>
                  </CheckoutItem>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <CheckoutSummary style={{ padding: '0 10% 0 10%' }}>
            <Grid container spacing={3} columns={{ xs: 1, md: 1, lg: 1, xl: 1 }}>
              <Grid item xs={1}>
                Prices includes postage and packaging for the UK mainland.
                If you are located outside of the UK mainland, please contact me first before placing your
                order to see how I can arrange delivery for you.
              </Grid>
              <Grid item xs={1}>
                <strong>Order total</strong> - GBP {orderTotal.toString()}
              </Grid>
              <Grid item xs={1}>
                Please check your order details and select your payment option here to complete your order.
              </Grid>
              <Grid item xs={1}>
                <PayPalButtons
                  createOrder={createOrder}
                  onApprove={onApprove}
                />
              </Grid>
            </Grid>
          </CheckoutSummary>
        </Grid>
      </Grid>
      <Dialog open={orderError !== undefined} >
        <IconButton
          aria-label="close"
          onClick={() => setOrderError(undefined)}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <CenterText>Sorry, your payment could not be processed.</CenterText>
          <CenterText>Feel free to give it another try. If the issue continues, really appreciate if you could let me know.</CenterText>
          {orderError?.debug_id && <CenterText>Error: <b>{orderError?.debug_id}</b></CenterText>}
          <br />
        </DialogContent>
      </Dialog>
    </div >
  );
};

export default Cart;
