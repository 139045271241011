import styled from "@emotion/styled";
import { Grid } from "@mui/material";
import Images from "../Images";

const ImageTile = styled.div`
  display: block;
  max-width: 390px;
`;

const About = () => {
  return (
    <Grid
      container
      rowSpacing={2}
      columnSpacing={5}
      columns={{ xs: 1, md: 2, lg: 2, xl: 2 }}
    >
      <Grid item xs={2}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={1}
          columns={{ xs: 2, md: 4, lg: 4, xl: 4 }}
        >
          {Images.filter((element) => element.featuredIndex).map((element) => {
            return (
              <Grid item xs={1} key={element.id}>
                <ImageTile>
                  <img
                    src={"/images/gallery/" + element.id + "/390x390.jpeg"}
                    width={"100%"}
                    alt={element.title}
                  />
                </ImageTile>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid item xs={1}>
      <p>
        Hi, I'm Bhagya Perera, a self-taught wildlife artist who finds solace 
        and delight in painting the wild life,primarily using acrylics on canvas. 
        </p>
        <p>
        Here on this website, you'll have the chance to explore my original artworks 
        and even acquire them for your collection. 
        </p>
      </Grid>
      <Grid item xs={1}>
      <p>
        Join me on this artistic journey as we celebrate the beauty of wildlife together. 
        I invite you to explore my gallery and share in 
        the wonder that nature inspires. 
        </p>
        <p>Thank you for visiting, and I hope my art brings a touch of the wild into your life.</p>
      </Grid>
    </Grid>
  );
};

export default About;
