import styled from "@emotion/styled";
import { Badge, BadgeProps, Grid, IconButton } from "@mui/material";
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { useSessionStorage } from 'usehooks-ts';
import { Link, useNavigate } from "react-router-dom";

const HeaderContainer = styled.header`
  background-color: #333;
`;

const HeaderGrid = styled(Grid)`
  max-width: 1500px;
  margin: 0 auto;
`;

const HeadingLink = styled(Link)` 
  text-decoration: none;
`

const Heading = styled.h1`
  padding: 10pt;
  color: #fff;
  margin: 0;
  font-size: 20pt;
  text-align: left;
  @media (max-width: 786px) {
    font-size: 15pt;
    padding-right: 0;
  }
`;

const CartIcon = styled(ShoppingCartOutlinedIcon)`
  color: #fff;
  font-size: 24pt;
  padding: 10px;
`

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 10,
    top: 10,
  },
}));

const Header = () => {
  const [cartContent] = useSessionStorage('cart-contents', []);
  let navigate = useNavigate();

  return (
    <HeaderContainer>
      <HeaderGrid container spacing={0}>
        <Grid item xs={7}>
          <HeadingLink to={"/"}><Heading>Bhagya Perera Art</Heading></HeadingLink>
        </Grid>
        <Grid item xs={5} alignContent={"end"} textAlign={"right"}>
          <IconButton onClick={() => navigate('/basket')}>
            <StyledBadge badgeContent={cartContent.length} color="primary">
              <CartIcon />
            </StyledBadge>
          </IconButton>
        </Grid>
      </HeaderGrid>
    </HeaderContainer>
  );
};

export default Header;
